import React, { useState, useEffect } from 'react';

import ReportStatusModule from './ReportStatusModule.js';
import ReportInfoModule from './ReportInfoModule.js';
import ReportAiFlagsModule from './ReportAiFlagsModule.js';
import ReportTimesModule from './ReportTimesModule.js';
import ReportNarrativeModule from './ReportNarrativeModule.js';
import ReportChecksModule from './ReportChecksModule.js';
import './Box.css';

const CheckResultsBox = ({user, reportId, isSmallScreen}) => {
    return (
        <div className={isSmallScreen ? "standard-box-screen-small" : "standard-box"}>
            <h1 className='box-title'> Report Feedback </h1>
            <ReportStatusModule user={user} reportId={reportId} isSmallScreen={isSmallScreen}/>
            <ReportInfoModule user={user} reportId={reportId} isSmallScreen={isSmallScreen}/>
            {user.subscriptions.includes('CADMUS_AUDITOR') && <ReportAiFlagsModule user={user} reportId={reportId} isSmallScreen={isSmallScreen}/>}
            {user.subscriptions.includes('CADMUS_QA') && <ReportTimesModule user={user} reportId={reportId} isSmallScreen={isSmallScreen} />}
            {user.subscriptions.includes('CADMUS_QA') && <ReportNarrativeModule user={user} reportId={reportId} isSmallScreen={isSmallScreen} />}
            {user.subscriptions.includes('CADMUS_QA') && <ReportChecksModule user={user} reportId={reportId} isSmallScreen={isSmallScreen} />}
        </div>
    );
}

export default CheckResultsBox;