import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Network from '../models/Network';
import URLS from '../urls.js';
import './Header.css';

const Header = ({ user, home }) => {
  const navigate = useNavigate();

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1600); // screen width cutoff
    };

    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleLogout = async () => {
    navigate('/login');

    const url = URLS.LOGOUT_URL
    const dict = {
        uid: user.uid,
        CLIENT_ACCESS_KEY: process.env.REACT_APP_CLIENT_ACCESS_KEY
    }

    await Network.post(url, dict, (response) => {
        const status = response.status
        if (Network.checkValidStatusAndResponse(response)) {
            console.log("Logged Out Successfully")
        }
    })          
  };

  const handleGoHome = async () => {
    navigate('/home'); 
  };

  return (
    <div className={isSmallScreen ? "header-container" : "header-container-behind"}>
        <header className={isSmallScreen ? "header-screen-small" : "header"}>
        <img src={require('../images/logo-dark-QA.png')} alt="Logo" className="logo"/>
        <div className="company-info">
          {home ? (
            <button className="go-home-button" onClick={handleGoHome}> Home </button>
          ) : (
            <div></div>
          )}
          <div className="company-name" style={{ fontSize: 24 }}>
            {user.agencyName}
          </div>
          <button className="logout-button" onClick={handleLogout}>
            Logout
          </button>
        </div>
        </header>
      {/* <div className="header-spacer"></div> */}
    </div>
  );
};

export default Header;