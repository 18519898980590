import React, { useState, useEffect } from 'react';

import Header from './Header.js';
import SortReportsBox from './SortReportsBox.js';
import Footer from './Footer.js';

const ServiceHome = ({ user }) => {
    
    const [isSmallScreen, setIsSmallScreen] = useState(false);

    useEffect(() => {
        const handleResize = () => {
          setIsSmallScreen(window.innerWidth < 1600); // screen width cutoff
        };
    
        handleResize(); // Initial check
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div>
            <Header user={user} />
            <div className='box-spacer'/>
            <SortReportsBox user={user} isSmallScreen={isSmallScreen}/>
            <Footer />
        </div>
    );
};

export default ServiceHome;