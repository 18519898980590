import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import LandingPage from './components/landing/LandingPage';
import AboutPage from './components/AboutPage';
import FaqPage from './components/FaqPage';
import BlogPage from './components/BlogPage';
import LoginPage from './components/LoginPage';
import ServiceHomePage from './components/ServiceHomePage';
import ReportPage from './components/ReportPage';
import './App.css';

function App() {

  const [user, setUser] = useState(null); // reset user state every time the app is mounted - prevents direct access of protected pages, even while logged in
  const [flags, setFlags] = useState(null);

  return (
    <Router>
      <div className="App">
        <Routes>
          {/* Open routes */}
          <Route path="/login" element={<LoginPage onLogin={setUser} />} />

          <Route path="/" element={<LandingPage/>} />

          <Route path="/about" element={<AboutPage/>} />

          <Route path="/faq" element={<FaqPage/>} />

          <Route path="/blog" element={<BlogPage/>} />

          {/* Protected routes - only accessible when user is logged in */}
          {user ? (
            <Route
              path="/home"
              element={<ServiceHomePage user={user} flags={flags}/>}
            />
          ) : null}

          {user ? (
            <Route
              // path="/report"
              path="/report/:reportId"
              element={<ReportPage user={user} flags={flags}/>}
            />
          ) : null}
          
          {/* Redirect to /login when no user is logged in, or if page is not routed*/}
          <Route
            path="/*" element={<Navigate to="/" />}
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
