import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Network from '../models/Network.js';

import ReportListElement from './ReportListElement.js';
import './Module.css';

const key = process.env.REACT_APP_CLIENT_ACCESS_KEY;
const bypass = process.env.REACT_APP_BYPASS_LOGIN;
const URLS = require("../urls.js");

const StatusCodes = require('../status-codes/status-codes.js')
const ErrorCodes = require('../error-codes/error-codes.js')

const ReportsListModule = ({ user, currentPage, reportsPerPage, isSmallScreen }) => {
    const navigate = useNavigate();

    const [jsonData, setJsonData] = useState(null);

    // function that gets multiple reports from the server
    const getSeveralReports = async (desiredNumber, reportOffset) => {
        
        const url = URLS.GET_SEVERAL_REPORTS;
        const dict = {
            uid: user.uid,
            number_of_reports: desiredNumber,
            offset: reportOffset,
            CLIENT_ACCESS_KEY: key
        };

        let responseData;

        await Network.post(url, dict, (response) => {

            responseData = response.data;

            const extractedErrorCode = responseData.toString();
            const status = response.status

            if (Network.checkValidStatusAndResponse(response)) {
                // do something

            } else if (status == StatusCodes.INTERNAL_SERVER_ERROR) {
                console.log("Server Error")

            } else {
                console.log("Unknown Error")
            }
        });

        return responseData;
    };

    const markReportRead = async (reportId) => {
        
        // const url = URLS.GET_SEVERAL_REPORTS;
        const url = URLS.MARK_REPORT_READ_UNREAD;

        const dict = {
            uid: user.uid,
            report_id: reportId,
            read_status: 'read',
            CLIENT_ACCESS_KEY: key
        };

        let responseData;

        await Network.post(url, dict, (response) => {

            responseData = response.data;

            const extractedErrorCode = responseData.toString();
            const status = response.status

            if (Network.checkValidStatusAndResponse(response)) {
                // do something

            } else if (status == StatusCodes.INTERNAL_SERVER_ERROR) {
                console.log("Server Error")

            } else {
                console.log("Unknown Error")
            }
        });

        return responseData;
    };

    
    // hook called on component mount - TODO: move this code so that it executes after everytime currentPage is updated
    useEffect(() => {
        getSeveralReports(reportsPerPage, currentPage*reportsPerPage)
            .then((data) => {
                setJsonData(data); // TODO: add protection against API failure
            })
            .catch((error) => {
                console.log('Error:', error);
            });
    }, [reportsPerPage, currentPage]);

    const handleReportButtonClick = (reportId) => {
        console.log("Report button pressed");
        if (bypass !== 'true') { // do not mark as read in dev mode
            // mark the report as read
            markReportRead(reportId);
        }
        navigate('/report/' + reportId);
    };

    const createReportListElement = (reportId, reportNumber, incidentNumber, numberOfIssues, reportType, incidentTime, read, finished) => {
        // if numberOfIssues is null, set it to 0
        if (numberOfIssues === null) {
            numberOfIssues = '-';
        }
        return (
            <ReportListElement
                // key={reportNumber} // TODO: React 'needs' a unique key to differentiate between elements - causing page progression bug
                reportNumber={reportNumber}
                incidentNumber={incidentNumber}
                numberOfIssues={numberOfIssues}
                reportType={reportType}
                incidentTime={incidentTime}
                read={read}
                finished={finished}
                onButtonClick={() => handleReportButtonClick(reportId)} // Pass the event handler to the component
            />
        );
    };

    return (
        <div className={isSmallScreen ? "standard-module-screen-small" : "standard-module"}>
            <div className="list-header">
                <div className='medium-div'>
                    <div style={{fontWeight: "bold"}}> Incident # </div>
                </div>
                <div className='small-div'>
                    <div style={{fontWeight: "bold"}}> Issues </div>
                </div>
                <div className='medium-div'>
                    <div style={{fontWeight: "bold"}}> Run Type(s) </div>
                </div>
                <div className='medium-div'>
                    <div style={{fontWeight: "bold"}}> Run Ended </div>
                </div>
                <button className="invisible-button"> View Report </button>
            </div>

            {jsonData ? (
                // console.log('report_id: ', jsonData[0].report_id),
                // console.log('type: ', jsonData[0].report_type),
                    jsonData.map((data) => createReportListElement(data.report_id, data.pcr_number, data.incident_number, data.number_issues,
                        data.report_type, data.unit_back_in_service, data.report_marked_read, data.report_marked_finished))
                ) : (
                    <div>Loading...</div>
                )}

        </div>
    );
}

export default ReportsListModule;