import React from 'react';
import { useNavigate } from 'react-router-dom';

import './LandingHeader.css';

const LandingHeader = (props) => {

    const navigate = useNavigate();

    const handleAboutNav = async () => {
        navigate("/about");
    }

    const handleServicesNav = async () => {
        navigate("/");
    }

    const handleFaqNav = async () => {
        navigate("/faq");
    }

    const handleBlogNav = async () => {
        navigate("/blog");
    }

    const handleSignInNav = async () => {
        navigate("/login");
    }

    return (
        <header className='landing-header'>
            <div className='content'>
                <img src={require('../images/logo-dark.png')} alt='Logo' onClick={handleServicesNav} className='logo' />
                <div className='links'>
                    <p onClick={handleAboutNav} className={(props.page === "about" ? "link curr-page" : "link")}>About Us</p>
                    <p onClick={handleServicesNav} className={(props.page === "services" ? "link curr-page" : "link")}>Our Services</p>
                    <p onClick={handleFaqNav} className={(props.page === "faq" ? "link curr-page" : "link")}>FAQ</p>
                    {/* <p onClick={handleBlogNav} className={(props.page === "blog" ? "link curr-page" : "link")}>Blog</p> */}
                    {/* <a href='https://www.cadmusanalytics.com/login' className='blue-btn'>Sign In</a>     */}
                </div>
                <p className='blue-btn' onClick={handleSignInNav}>Sign In</p>    
            </div>
        </header>
    )
}

export default LandingHeader;