import React, { useState, useEffect } from 'react';
import Network from '../models/Network.js';

import DualColListElement from './DualColListElement.js';
import './Module.css';

const key = process.env.REACT_APP_CLIENT_ACCESS_KEY;
const URLS = require("../urls.js");

const StatusCodes = require('../status-codes/status-codes.js')
const ErrorCodes = require('../error-codes/error-codes.js')

const ReportAiFlagsModule = ({user, reportId, isSmallScreen}) => {
    const [aiFlagsData, setAiFlagsData] = useState(null);

    // function that gets one report's ai flags from the server
    const getOneReportAiFlags = async (reportId) => {
        const url = URLS.GET_ONE_REPORT_AI_FLAGS;
        const dict = {
            uid: user.uid,
            report_id: reportId,
            CLIENT_ACCESS_KEY: key
        };
        let responseData;

        await Network.post(url, dict, (response) => {
            responseData = response.data;
            const extractedErrorCode = responseData.toString();
            const status = response.status

            if (Network.checkValidStatusAndResponse(response)) {
                // do something

            } else if (status == StatusCodes.INTERNAL_SERVER_ERROR) {
                console.log("Server Error")

            } else {
                console.log("Unknown Error")
            }
        });
        return responseData;
    };

    useEffect(() => {
        // setAiFlagsData('this');
        getOneReportAiFlags(reportId)
            .then((data) => {
                // console.log('Data:', data);
                setAiFlagsData(data);
            })
            .catch((error) => {
                console.log('Error:', error);
            });
    }, []);

    const createInfoListElement = (elementName, elementValue) => {
        return (
            elementValue !== null && elementValue !== 'null' ? ( // do not show value if null or 'null'
            <DualColListElement
            elementName={elementName}
            elementValue={elementValue}   
            />
            ) : null
        );
    };

    return (
        <div className={isSmallScreen ? "standard-module-screen-small" : "standard-module"}>
            <h2 className='module-title'>Report AI Flags</h2>
            {aiFlagsData ? (
                <div>
                    <div className="list-header">
                        <div className="small-div">
                            <div style={{fontWeight: "bold"}}> Title </div>
                        </div>
                        <div className="small-div">
                            <div style={{fontWeight: "bold"}}> Suggestion </div>
                        </div>
                    </div>
                    {/* {createInfoListElement('Narrative Suggestion: Barriers of Care Ambiguous in Narrative', 'suggestion')} */}
                    {aiFlagsData.map((flag, index) => (
                        createInfoListElement(flag.title, flag.suggestion)
                    ))}
                </div>
            ) : (
                <div>Loading Report Data...</div>
            )}
        </div>
    );
}

export default ReportAiFlagsModule;