import React, { useState, useEffect } from 'react';
import Network from '../models/Network.js';

import './CheckListElement.css';

import TypeTag from './TypeTag.js';
import MedicTag from './MedicTag.js';
import ImpactAttributeTag from './ImpactAttributeTag.js';

const key = process.env.REACT_APP_CLIENT_ACCESS_KEY;
const URLS = require("../urls.js");

const StatusCodes = require('../status-codes/status-codes.js')
const ErrorCodes = require('../error-codes/error-codes.js')

const CheckListElement = ({ checkData, onButtonClick }) => {
    const [overriddenStatus, setOverriddenStatus] = useState(checkData.manual_override);

    const handleButtonClick = () => {
        // Use the functional form of setState
        setOverriddenStatus(prevStatus => {
            const newStatus = prevStatus ? 0 : 1;
    
            // Pass updated status to parent component
            let checkToUpdate = {checkData: checkData, overriddenStatus: newStatus};
            // console.log('checkToUpdate (child):', checkToUpdate);
            onButtonClick(checkToUpdate);
    
            return newStatus;
        });
    
        console.log('overriddenStatus changed');
    };
    

    return (
        <div className="list-element" style={{ backgroundColor: checkData.result === 1 ? '#d4fad4' : '#fad4d4' }}>
            <div className="small-div" style={{ display: 'flex', flexDirection: 'column'}}>
                <div className='large-div' style={{ display: 'flex', flexDirection: 'row' }}>
                    <div className={checkData.result === 0 ? 'medium-div' : 'small-div'}>
                        <TypeTag reportType={checkData.test_type} isButton={false}/>
                        <ImpactAttributeTag attributeType={checkData.impact_attribute} isButton={false}/>
                    </div>
                    <div className="large-div" style={{ justifyContent: 'center' }}>
                        <div className="element-name" style={{ fontSize: 'larger' }}> {checkData.test_name} </div>
                    </div>
                    {checkData.result === 0 && (
                        <div className="small-div" style={{ justifyContent: 'right' }}>
                            <button className="enter-button" onClick={handleButtonClick}>{overriddenStatus ? 'Reinstate' : 'Override'}</button>
                        </div>
                    )}
                    <div className="small-div" style={{ justifyContent: 'right' }}>
                        <div className="element-value" style={{ color: checkData.result ? '#29ba46' : '#e83838' }}> {checkData.result ? 'Passed' : 'Failed'} </div>
                    </div>
                </div>
                

                {!checkData.result && !overriddenStatus && ( // if there is no issue, do not show issue message and failure mode
                    <>
                        <div className="small-div" style={{ marginTop: 20 }}>
                            <p><strong><u>Issue</u>:</strong> {checkData.issue_message}</p>
                        </div>
                        {checkData.failure_mode && ( // if failure mode is not null, show failure mode
                            <div className="small-div" style={{ marginTop: 20 }}>
                                <p><strong><u>Reason</u>:</strong> {checkData.failure_mode}</p> 
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default CheckListElement;