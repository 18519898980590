import React, { useState, useEffect } from 'react';
import Network from '../models/Network.js';

import DualColListElement from './DualColListElement.js';
import CheckListElement from './CheckListElement.js';
import './Module.css';

const key = process.env.REACT_APP_CLIENT_ACCESS_KEY;
const URLS = require("../urls.js");

const StatusCodes = require('../status-codes/status-codes.js')
const ErrorCodes = require('../error-codes/error-codes.js')

const ReportChecksModule = ({user, reportId, isSmallScreen}) => {
    const [allChecksData, setAllChecksData] = useState(null);
    const [checkToUpdate, setCheckToUpdate] = useState(null);

    // function that gets one report's time data from the server
    const getOneReportChecks = async (reportId) => {
        const url = URLS.GET_ONE_REPORT_CHECKS;
        const dict = {
            uid: user.uid,
            report_id: reportId,
            CLIENT_ACCESS_KEY: key
        };
        let responseData;

        await Network.post(url, dict, (response) => {
            responseData = response.data;
            const extractedErrorCode = responseData.toString();
            const status = response.status

            if (Network.checkValidStatusAndResponse(response)) {
                // do something

            } else if (status == StatusCodes.INTERNAL_SERVER_ERROR) {
                console.log("Server Error")

            } else {
                console.log("Unknown Error")
            }
        });
        return responseData;
    };

    const updateOverriddenStatus = async (checkData, overriddenStatus) => {
        
        const url = URLS.UPDATE_CHECK_OVERRIDE;

        let currentOverriddenStatus = overriddenStatus ? 'overridden' : 'unoverridden';

        console.log('Current Overridden Status being pushed:', currentOverriddenStatus);
        
        const dict = {
            overridden_status: currentOverriddenStatus,
            agency_uid: checkData.reports_agencies_uid,
            report_id: checkData.reports_report_id,
            test_metadata_id: checkData.test_metadata_test_metadata_id,
            CLIENT_ACCESS_KEY: key
        };

        let responseData;

        await Network.post(url, dict, (response) => {

            responseData = response.data;

            const extractedErrorCode = responseData.toString();
            const status = response.status

            if (Network.checkValidStatusAndResponse(response)) {
                // do something

            } else if (status == StatusCodes.INTERNAL_SERVER_ERROR) {
                console.log("Server Error")

            } else {
                console.log("Unknown Error")
            }
        });

        console.log("response data:", responseData);
        return responseData;
    };

    useEffect(() => {
        getOneReportChecks(reportId)
            .then((results) => {
                setAllChecksData(results);
                // console.log(results);
                // console.log(results[0]);
                // Object.keys(results[0]).map((key) => {
                //     console.log(key);
                // });
            })
            .catch((error) => {
                console.log('Error:', error);
            });
    }, []);

    useEffect(() => {
        if (checkToUpdate !== null && checkToUpdate !== undefined) {
            updateOverriddenStatus(checkToUpdate.checkData, checkToUpdate.overriddenStatus);
        }
    }, [checkToUpdate]);

    const handleOverrideButtonClick = (check) => {
        // extract json from check
        let checkToUpdate = {checkData: check['checkData'], overriddenStatus: check['overriddenStatus']
        };
        // console.log('checkToUpdate (parent, extracted):', checkToUpdate)
        setCheckToUpdate(checkToUpdate);
    };

    const createCheckListElement = (checkData) => {
        // return (createInfoListElement(checkData['report_col_name'], checkData['result']));
        return (
            checkData['result'] !== null && checkData['result'] !== 'null' ? ( // do not show value if null or 'null'
                <CheckListElement
                    checkData={checkData}
                    onButtonClick={handleOverrideButtonClick}
                    />
            ) : null
        );
    };

    return (
        <div className={isSmallScreen ? "standard-module-screen-small" : "standard-module"}>
            <h2 className='module-title'> Report Checks </h2>
            
            {/* // if allchecksdata exists and is not null, map over it and create a list element for each check */}
            {allChecksData && allChecksData.length > 1 ? (
                <div>
                    {allChecksData.map((checkData) => (createCheckListElement(checkData)))}
                </div>
            ) : (
                <div>Loading Report Data...</div>
            )}

        </div>
    );
}

export default ReportChecksModule;