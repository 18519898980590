import React, {useState, useEffect} from "react";
import './FilterDropdown.css';

import TypeTag from "./TypeTag";

const FilterDropdown = ({title, options, selected, updateSelected, multiple}) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleOptionClick = (option) => {
        updateSelected((prevSelected) => {
            if (multiple) {
                return prevSelected.includes(option) 
                ? prevSelected.filter(item => item !== option) 
                : [...prevSelected, option];
            } else {
                return [option];
            }
        })
    }

    const selectAll = () => {
        updateSelected(Object.keys(options));
    }

    const clearAll = () => {
        updateSelected([]);
    }

    useEffect(() => {
        console.log("Selected options:", selected);
    }, [selected]); // debug

    return (
        <div className={"filter-dropdown " + title}>
            <button 
            className="select-btn"
            aria-label="select button"
            aria-haspopup="listbox"
            aria-expanded={isOpen}
            aria-controls="select-dropdown"
            onClick={(e) => setIsOpen(!isOpen)}
            >
                <span className="title">{multiple ? title : options[selected]}</span>
                <i className="fa-solid fa-angle-down"></i>
            </button>
            <ul className={isOpen ? "options-box open" : "options-box"} role="listbox">
                {Object.keys(options).map((option) => (
                    <li 
                        key={option}
                        className="option" 
                        role="option" 
                        onClick={() => handleOptionClick(option)}
                    >
                        <input 
                            type={multiple ? "checkbox" : "radio"} 
                            id={option} 
                            checked={selected.includes(option)} 
                            readOnly
                        />
                        {(title === "Type Tags") ?
                            <TypeTag reportType={option} isButton={false} />
                        :
                        <label htmlFor={option}>{options[option] ? options[option] : option}</label> // query since the display value of the option (ie medic name) may be null
                        }
                    </li>
                ))}
                {multiple
                ? 
                <div className="select-ctrls">
                    <span onClick={() => selectAll()}>Select All</span>
                    <span onClick={() => clearAll()}>Clear</span>
                </div>
                :
                ""
                }
            </ul>
        </div>
    )
};

export default FilterDropdown;