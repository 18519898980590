import React, { useState, useEffect } from 'react';

import ReportsListModule from './ReportsListModule.js';
import ReportsListFilteredModule from './ReportsListFilteredModule.js';
import './Box.css';

const LatestReportsBox = ({user, isSmallScreen}) => {

    return (
        <div className={isSmallScreen ? "standard-box-screen-small" : "standard-box"}>

            <h1 className='box-title'> Latest Reports </h1>

            {/* <div style={{ justifyContent: 'center' }}>
                <p>{reportsPerPage} Reports per Page</p>
            </div> */}

            <ReportsListFilteredModule
            user={user}
            isSmallScreen={isSmallScreen}
            />

            {/* <ReportsListModule
            user={user}
            currentPage={currentPage}
            reportsPerPage={reportsPerPage}
            isSmallScreen={isSmallScreen}
            /> */}

            {/* <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', margin: 'auto', width: '40%', marginBottom: '20px'}}>
                <div className='small-div' style={{justifyContent: 'center'}}>
                    {currentPage > 0 ?
                        <button className="box-button" onClick={() => handlePrevPageButtonClick()}>Prev Page</button>
                    : null}
                </div>
                <div className='small-div' style={{justifyContent: 'center'}}>
                    <p>Page {currentPage + 1}</p>
                </div>
                <div className='small-div' style={{justifyContent: 'center'}}>
                    <button className="box-button" onClick={() => handleNextPageButtonClick()}>Next Page</button>
                </div>
            </div> */}

        </div>
    );
};

export default LatestReportsBox;