import React from "react";

import LandingHeader from "./LandingHeader";
import LandingFooter from "./LandingFooter";

import './BlogPage.css';

const BlogPage = () => {
    return (
        <>
            <LandingHeader page="blog"/>
            <div className="blog-page content">
                <div className="standard-title">
                    <h1>Writings from our Team</h1>
                    <span>Hear our thoughts</span>
                </div>
                <hr className="post-title"/>

                <div className="tags">
                    <span className="tag">All</span>
                    <span className="tag">Software</span>
                    <span className="tag">EMS</span>
                    <span className="tag">QI</span>
                    <span className="tag">QA</span>
                </div>
                <div className="articles-grid">
                    <div className="article">
                        <img src="https://images.unsplash.com/photo-1488590528505-98d2b5aba04b?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" />
                        <p className="writer">Brad Greer</p>
                        <h3 className="title">Presenting a Startup Idea</h3>
                        <p className="desc">Lorem ipsum odor amet, consectetuer adipiscing elit.</p>
                    </div>
                </div>
            </div>
            <LandingFooter />
        </>
    )
}

export default BlogPage;