import React, { useState, useEffect } from "react";
import Network from '../models/Network.js';

import * as vega from 'vega';
import * as vegaLite from 'vega-lite';
import * as vl from 'vega-lite-api';
import { Handler } from 'vega-tooltip';
import { json } from "d3";

const key = process.env.REACT_APP_CLIENT_ACCESS_KEY;
const URLS = require("../urls.js");

const StatusCodes = require('../status-codes/status-codes.js')

const dark = '#3e3c38';

const config = {
    axis: {
        domain: false,
        tickColor: 'lightGray',
    },
    style: {
        'guide-label': {
            fontSize: 20,
            fill: dark
        },
        'guide-title': {
            fontSize: 30,
            fill: dark,
            labelLimit: 0
        }
    }
};

const viz = vl.markPoint({ filled: true, stroke: false, size: 800, opacity: 0.25 })
    .encode(
        //vl.x().fieldQ('report_id').scale({ zero: false }),
        vl.x().fieldT('creation_timestamp').scale({ zero: false }),
        vl.y().fieldQ('number_issues').scale({ zero: false }),
        vl.tooltip().fieldN('agency_id')
    )

vl.register(vega, vegaLite, {
    view: { renderer: 'svg' },
    init: view => { view.tooltip(new Handler().call); }
});

const Graph = () => {

    const [jsonData, setJsonData] = useState(null);
    const [marks, setMarks] = useState(null);
    const [vegaView, setVegaView] = useState(null);

    // function that gets multiple reports from the server
    const getSeveralReports = async (desiredNumber, reportOffset) => {
        
        const url = URLS.GET_SEVERAL_REPORTS_ANALYTICS;
        const dict = {
            // uid: user.uid,
            uid: 4,
            number_of_reports: desiredNumber,
            offset: reportOffset,
            CLIENT_ACCESS_KEY: key
        };

        let responseData;

        await Network.post(url, dict, (response) => {

            responseData = response.data;

            const extractedErrorCode = responseData.toString();
            const status = response.status

            if (Network.checkValidStatusAndResponse(response)) {
                // do something

            } else if (status == StatusCodes.INTERNAL_SERVER_ERROR) {
                console.log("Server Error")

            } else {
                console.log("Unknown Error")
            }
        });

        return responseData;
    };

    useEffect(() => {
        getSeveralReports(80, 0) // # reports, offset
            .then((data) => {
                console.log('data: ', data[0]);
                setJsonData(data);
                // console.log('json data set: ', jsonData);
                setMarks(viz
                    .data(data)
                    .width(window.innerHeight)
                    .height(window.innerHeight)
                    .autosize({ type: 'fit', contains: 'padding' })
                    .config(config)
                );
                // console.log('marks set: ', marks);
            })
            .catch((error) => {
                console.log('Error:', error);
            });
    }, []);

    useEffect(() => {
        if (marks) {
            marks.render().then(view => {
                setVegaView(view);
            }).catch(error => {
                console.error('Error rendering Vega view:', error);
            });
        }
    }, [marks]);

    return (
        <>
            {jsonData && vegaView ? (
                <div ref={(div) => {
                    if (div && vegaView && div.children.length === 0) {
                        div.appendChild(vegaView);
                    }
                }} />
            ) : (
                <div>Loading...</div>
            )}
        </>
    );
};

export default Graph;