import React from "react";

import "./LandingTitle.css";

import Smiley from "../Smiley.js";
import Graph from "../Graph.js";
import GraphReportsScatter from "../GraphReportsScatter.js";
import GraphReportBar from "../GraphReportsBar.js";

const LandingTitle = () => {
    return (
        <div className="landingTitle">
            <div className="content row">
                <div className="col text">
                    <h1>Accelerate and Improve Your EMS Quality Workflow</h1>
                    
                    <a className="demo-btn" href="#demo">
                        View a demo
                        <i className="fa-solid fa-chevron-right"></i>
                    </a>
                    
                </div>
                <div className="col graphs">
                    <img className="front" src="https://images.unsplash.com/photo-1579037005241-a79202c7e9fd?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="graph"/>
                    {/* put Smiley here */}
                    {/* <Smiley/> */}
                    {/* <Graph/> */}
                    {/* <GraphReportsScatter/> */}
                    {/* <GraphReportBar/> */}
                    {/* <img className="front" src="https://images.unsplash.com/photo-1591696205602-2f950c417cb9?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="graph"/> */}
                    {/* <img className="back" src="https://images.unsplash.com/photo-1516383274235-5f42d6c6426d?q=80&w=2674&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="graph"/> */}
                </div>
            </div>
        </div>
    )
}

export default LandingTitle;